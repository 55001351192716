var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t("drawer.DatasetGroups")))]),_c('v-btn',{attrs:{"text":"","to":"/datasetGroups/create","color":"primary"}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',[_vm._v("CREATE")])],1)],1),_c('v-divider'),_c('v-container',[_c('v-row',[_c('v-col',[_c('v-card-title',{staticClass:"pt-0 px-0"},[_c('v-spacer'),_c('v-text-field',{staticClass:"shrink",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-key":"id","loading":_vm.isLoading,"loading-text":_vm.$t('loadingInfo'),"items-per-page":-1,"hide-default-footer":"","expanded":_vm.expanded,"show-expand":"","single-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"indigo"},on:{"click":function($event){return _vm.onView(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("view")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.onStartDatasetGroupDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.onDeleteDatasetGroup(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("delete")))])])],1)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":12}},[_c('div',[_vm._v(_vm._s(item.description))])])]}}])})],1)],1)],1)],_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialogDatasetGroup),callback:function ($$v) {_vm.dialogDatasetGroup=$$v},expression:"dialogDatasetGroup"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" Edit project ")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('form',{staticClass:"mt-5"},[_c('v-text-field',{attrs:{"label":"Project name","error-messages":_vm.datasetGroupNameErrors,"required":"","dense":""},on:{"change":function($event){return _vm.$v.datasetGroup.name.$touch()},"blur":function($event){return _vm.$v.datasetGroup.name.$touch()}},model:{value:(_vm.datasetGroup.name),callback:function ($$v) {_vm.$set(_vm.datasetGroup, "name", $$v)},expression:"datasetGroup.name"}}),_c('v-textarea',{attrs:{"label":"Descripstion","error-messages":_vm.datasetGroupDescriptionErrors,"rows":"1","row-height":"20"},on:{"change":function($event){return _vm.$v.datasetGroup.description.$touch()},"blur":function($event){return _vm.$v.datasetGroup.description.$touch()}},model:{value:(_vm.datasetGroup.description),callback:function ($$v) {_vm.$set(_vm.datasetGroup, "description", $$v)},expression:"datasetGroup.description"}})],1)])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onCloseDatasetGroupDialog}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.onUpdateBtnLocked},on:{"click":_vm.onEditDatasetGroup}},[_vm._v(" Update ")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }