<template>
  <div style="height: 100%">
    <!-- loading animation -->
    <!-- <template v-if="loading">
      <v-container fluid style="height: 100%">
        <v-row align="center" justify="center" style="height: 100%">
          <v-col class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </template> -->

    <!-- data content -->
    <template>
      <!-- toolbar -->
      <v-toolbar dense flat>
        <v-toolbar-title class="mr-2">{{
          $t("drawer.DatasetGroups")
        }}</v-toolbar-title>

        <v-btn text to="/datasetGroups/create" color="primary">
          <v-icon>mdi-plus</v-icon>
          <span>CREATE</span>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>

      <v-container>
        <v-row>
          <v-col>
            <v-card-title class="pt-0 px-0">
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="shrink"
              ></v-text-field>
            </v-card-title>
            <v-data-table
              dense
              :headers="headers"
              :items="items"
              :search="search"
              item-key="id"
              class="elevation-1"
              :loading="isLoading"
              :loading-text="$t('loadingInfo')"
              :items-per-page="-1"
              hide-default-footer
              :expanded.sync="expanded"
              show-expand
              single-expand
            >
              <template v-slot:item.actions="{ item }">
                <div class="d-flex">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="indigo"
                        v-bind="attrs"
                        v-on="on"
                        @click="onView(item)"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("view") }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                        @click="onStartDatasetGroupDialog(item)"
                      >
                        <v-icon>mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("edit") }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="onDeleteDatasetGroup(item)"
                      >
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("delete") }}</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:expanded-item="{ item }">
                <td :colspan="12">
                  <div>{{ item.description }}</div>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <!-- Update dataset group info -->
    <v-dialog v-model="dialogDatasetGroup" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5"> Edit project </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <form class="mt-5">
                  <v-text-field
                    v-model="datasetGroup.name"
                    label="Project name"
                    :error-messages="datasetGroupNameErrors"
                    required
                    @change="$v.datasetGroup.name.$touch()"
                    @blur="$v.datasetGroup.name.$touch()"
                    dense
                  ></v-text-field>

                  <v-textarea
                    label="Descripstion"
                    v-model="datasetGroup.description"
                    :error-messages="datasetGroupDescriptionErrors"
                    @change="$v.datasetGroup.description.$touch()"
                    @blur="$v.datasetGroup.description.$touch()"
                    rows="1"
                    row-height="20"
                  ></v-textarea>
                </form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseDatasetGroupDialog">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onEditDatasetGroup"
            :disabled="onUpdateBtnLocked"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import data from "@/models/data";
import _ from "lodash";
import { formatTimeToClientTimezone } from "@/utils";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "DatasetList",
  data: () => ({
    search: "",
    items: [],
    datasetGroup: {
      name: null,
      description: null,
    },
    dialogDatasetGroup: null,
    item: null,
    onUpdateBtnLocked: false,
  }),
  validations: {
    datasetGroup: {
      name: {
        required,
        maxLength: maxLength(32),
      },
      description: {
        maxLength: maxLength(512),
      },
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.loader.loading;
    },
    headers() {
      return [
        // { text: this.$t("name"), value: "dataset_name" },
        // { text: "ID", value: "UID" },
        // { text: this.$t("createdTime"), value: "created_time" },
        // { text: this.$t("status"), value: "status" },
        // { text: this.$t("action"), value: "actions", sortable: false },
        { text: this.$t("name"), value: "name" },
        { text: "ID", value: "id" },
        { text: this.$t("projectObjective"), value: "display_name" },
        { text: this.$t("createdTime"), value: "create_time" },
        // { text: this.$t("status"), value: "status" },
        { text: this.$t("action"), value: "actions", sortable: false },
      ];
    },
    loading() {
      return this.$store.state.loader.loading;
    },
    datasetGroupNameErrors() {
      const errors = [];
      if (!this.$v.datasetGroup.name.$dirty) return errors;
      !this.$v.datasetGroup.name.required && errors.push(this.$t("required"));
      console.log(this.$v.datasetGroup.name.$params);
      !this.$v.datasetGroup.name.maxLength &&
        errors.push(
          `Must be at most ${this.$v.datasetGroup.name.$params.maxLength.max}`
        );
      return errors;
    },
    datasetGroupDescriptionErrors() {
      const errors = [];
      if (!this.$v.datasetGroup.description.$dirty) return errors;
      !this.$v.datasetGroup.description.maxLength &&
        errors.push(
          `Must be at most ${this.$v.datasetGroup.description.$params.maxLength.max}`
        );
      return errors;
    },
  },
  methods: {
    async getDatasetList() {
      let items = await data.getDatasetGroups();
      items = items.data.DatasetGroup;
      const datasetGroupDomains = await data.getDatasetGroupDomains();
      items = _.orderBy(items, ["id"], ["desc"]);
      items.forEach((item) => {
        const domain = datasetGroupDomains.find((domain) => {
          return domain.id === item.domain.id;
        });
        item["display_name"] = domain.display_name;
        if (item.created_at) {
          item["create_time"] = formatTimeToClientTimezone(
            item.created_at.split(".")[0]
          );
        }
      });
      this.items = items;
    },
    async onEditDatasetGroup() {
      this.onUpdateBtnLocked = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        // valid
        console.log("this.$v.$invalid", this.$v.$invalid);
        if (this.$v.datasetGroup.$invalid) {
          console.log("this.$v.datasetGroup", this.$v.datasetGroup.$invalid);
        }
      } else {
        const payload = {
          datasetGroupId: this.item.id,
          name: this.datasetGroup.name,
          description: this.datasetGroup.description,
        };
        try {
          const res = await data.updateDatasetGroup(payload);
          this.getDatasetList();
          console.log("res", res);
          if (res.status !== 200) {
            this.$dialog.message.warning("Upadate project error.", {
              position: "top",
            });
          } else if (res.status === 200) {
            this.onCloseDatasetGroupDialog();
            this.$dialog.message.success("Upadate project successfully.", {
              position: "top",
            });
          } else {
            this.$dialog.message.error(this.$t("systemError"), {
              position: "top",
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
      this.onUpdateBtnLocked = false;
    },
    async onDeleteDatasetGroup(item) {
      let res = await this.$dialog.error({
        text: `${this.$t("deleteInfo")} ${item.name} ?`,
        title: this.$t("warning"),
        actions: {
          false: this.$t("no"),
          true: {
            color: "red",
            text: this.$t("yes"),
          },
        },
      });

      if (res === true) {
        const payload = {
          dataset_group_id: item.id,
        };
        await data.deleteDatasetGroup(payload);
        this.getDatasetList();
      }
    },
    onView(item) {
      console.log(item);
      //this.$router.push(`/datasetGroups/${item.id}`);
      this.$router.push({
        path: `/datasetGroups/${item.id}`,
        query: {
          name: item.name,
        },
      });
    },
    onStartDatasetGroupDialog(item) {
      this.dialogDatasetGroup = true;
      this.item = item;
      this.datasetGroup.name = this.item.name;
      this.datasetGroup.description = this.item.description;
    },
    onCloseDatasetGroupDialog() {
      this.dialogDatasetGroup = false;
    },
  },
  created() {
    this.getDatasetList();
  },
};
</script>

<style scope>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
